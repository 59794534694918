import './App.css';
import ErrorModalM from './modals/Error';
import React, {useContext, useEffect, useState} from "react";
import {useReadContract, useWalletClient} from "wagmi";
import {nftContract, mkfCoinContract, flareWhitelistContract, quoterContract} from "./models/abi";
import {Address} from "./models/types";
import { config } from "./wagmiConfig";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import ErrorModal from "./Error";
import JSBI from 'jsbi';

import {ConnectKitButton} from "connectkit";
import {UserContext} from "./UserContext";
type Props ={
  name: string | null;
}
const uint256MaxValue = BigInt("115792089237316195423570985008687907853269984665640564039457584007913129639935");

const tokenToUint256 = (raw: string, decimals: number) => {
  raw = raw.replace(',','.');
  if (raw === "" || raw === undefined) {
    return BigInt(0);
  }
  let splittedInt = ""
  let splittedDecimals = ""
  if (raw.split(".").length <= 1) {
    return BigInt(raw + "000000000000000000")
  }
  splittedInt = raw.split(".")[0];
  splittedDecimals = raw.split(".")[1];
  splittedInt = splittedInt
    .split("")
    .filter((d) => "0123456789".includes(d))
    .join("")
    .normalize();

  if (splittedDecimals.length <= decimals) {
    splittedDecimals = splittedDecimals.padEnd(decimals, "0");
  }
  return BigInt(splittedInt + splittedDecimals);
};

function App({name} : Props) {
  const [balanceOf, setBalanceOf] = useState<bigint | undefined>(undefined);
  const [valueInUsd, setValueInUsd] = useState(null as bigint | null);
  const [threshholdValue, setThreshholdValue] = useState(null as bigint | null);
  const [stakeValue, setStakeValue] = useState(null as string | null);
  const [stakedValue, setStakedValue] = useState(null as bigint | null);
  const [lastCall, setLastCall] = useState(null as string | null);
  const [success, setSuccess] = useState(null as string | null);
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxToken, ctxSetToken, ctxSuccess, ctxSetSuccess,
        ctxSetIsLoading, ctxError, ctxSetError, ctxLastError, ctxSetLastError, ctxIsParamCorrect, 
        ctxSetIsWhitelisted, ctxSetIsRemoved, ctxSetIsEnabled, ctxIsEnabled, ctxIsWhitelisted, ctxIsRemoved,
        ctxAuthenticated, ctxRegistrationName, ctxRegistrationEmail} = useContext(UserContext);
  const {data: walletClient} = useWalletClient();
  const [mkfAllowance, setMkfAllowance] = useState(BigInt(0) as BigInt);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [wasWarned, setWasWarned] = useState(false);
  const [showError, setShowError] = useState(false);

  let {
    writeContract,
    data: hash,
    error,
    isSuccess,
    isError,
    isPending,
  } = useWriteContract({config});

  const {isLoading: isConfirming, isSuccess: isConfirmed} =
    useWaitForTransactionReceipt({
      hash,
    });
  let isLoading = false;
  let isChanging = 0;
  if (isPending || isConfirming || ctxIsLoading || (ctxAddress != null && ctxIsWhitelisted == null) ) {
    isLoading = true;
  }
  if (isConfirmed || isError) {
    isLoading = false;
  }


  if (isSuccess && lastCall && isConfirmed){
    isChanging++;
    switch(lastCall){
      case 'enterWhitelist':
        ctxSetError("Whitelist Joined!");
        setSuccess("Whitelist Joined!");
        ctxSetSuccess("WhitelistJoined");
        ctxSetIsWhitelisted(true);
        ctxSetIsRemoved(false);
        break;
      case 'exitWhitelist':
        setSuccess("Exited Successfully!");
        ctxSetError("Exited Successfully!");
        ctxSetSuccess("ExitedSuccessfully");
        ctxSetIsWhitelisted(false);
        ctxSetIsRemoved(false);
        break;
      case 'Approve':
        setLastCall('enterWhitelist');
        writeContract({
          ...flareWhitelistContract,
          functionName: "enterWhitelist",
          args: [
            BigInt(JSBI.divide(JSBI.multiply(JSBI.BigInt(tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18).toString()), JSBI.BigInt(10e17)), JSBI.BigInt(valueInUsd?.toString() ?? "1")).toString())
          ],
        });
        break;
      default:
        break;
    }
    setLastCall(null);
  }
  if (isError && lastCall){
    switch(lastCall){
      case 'enterWhitelist':
        ctxSetError("Registration Request Failed");
        break;
      case 'exitWhitelist':
        ctxSetError("Exit Failed");
        break;
      default:
        break;
    }
    setLastCall(null);
  }
  const responseDataNft = useReadContract({
    ...nftContract,
    functionName: "getNFTInfo",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });

  function FormatUsdt(rawUsdt: string) : string {
    const newUsdtStr = rawUsdt.split('.')[0] + rawUsdt.split('.')[1].substring(0,2);
    return newUsdtStr
  }
  if (
    ctxAddress &&
    responseDataNft.data !== null &&
    responseDataNft.data !== undefined &&
    ((responseDataNft.data[0] > 0 && ctxToken !== responseDataNft.data[1]) || (responseDataNft.data[1] <= 0 && ctxToken !== BigInt(-1)))
  ) {
    if (responseDataNft.data[1] <= 0) {
      ctxSetToken(BigInt(-1));
    } else {
      ctxSetToken(responseDataNft.data[0]);
    }
  }

  function twoDecimals (str: string) {
    if (str.indexOf(".") <= 0) {
      return str
    }
    var strs = str.split(".");
    return strs[0] + "." + strs[1].substring(0,2);
  }

  const {data: mkfAllowanceData, refetch: mkfAllowanceRefetch} =
    useReadContract({
      ...mkfCoinContract,
      functionName: "allowance",
      args: [
        ctxAddress || "0x0000000000000000000000000000000000000000",
        flareWhitelistContract.address,
      ],
      query: {},
    });
  if (
    ctxAddress &&
    mkfAllowanceData !== null &&
    mkfAllowanceData !== undefined &&
    mkfAllowanceData !== mkfAllowance
  ) {
    setMkfAllowance(mkfAllowanceData as BigInt);
  }
  const responseDataWhitelistStatus = useReadContract({
    ...flareWhitelistContract,
    functionName: 'isUserWhitelisted',
    args: [ctxAddress],
    query: {enabled: false,},
  });

  if (
    ctxAddress != null &&
    (ctxSuccess != "WhitelistJoined" && ctxSuccess != "ExitedSuccessfully") &&
    responseDataWhitelistStatus.data !== null &&
    responseDataWhitelistStatus.data !== undefined &&
    (ctxIsWhitelisted && ((responseDataWhitelistStatus.data as (boolean)[])[0] !== ctxIsWhitelisted)
    || (responseDataWhitelistStatus.data as (bigint)[])[1] !== stakedValue) &&
    success == null &&
    isChanging <= 0
  ) {
    isChanging++;
    if((responseDataWhitelistStatus.data as (boolean|bigint)[])[0]){
      ctxSetIsWhitelisted(true);
      ctxSetIsRemoved(false);
      setStakedValue((responseDataWhitelistStatus.data as (bigint)[])[1]);
    } else if ((responseDataWhitelistStatus.data as (bigint)[])[1] > BigInt("0")){
      ctxSetIsWhitelisted(false);
      ctxSetIsRemoved(true);
      setStakedValue((responseDataWhitelistStatus.data as (bigint)[])[1]);
    } else {
      ctxSetIsWhitelisted(false);
      ctxSetIsRemoved(false);
    }
  }

  const responseDataUsdPriceData = useReadContract({
    ...quoterContract,
    functionName: "getTokenPriceInUSD",
    args: [mkfCoinContract.address],
    query: {},
  });

  if (
    responseDataUsdPriceData.data !== null &&
    responseDataUsdPriceData.data !== undefined &&
    valueInUsd == null
  ) {
    setValueInUsd(responseDataUsdPriceData.data as bigint);
  }
  const responseDataFlareStatus = useReadContract({
    ...flareWhitelistContract,
    functionName: "getFlareStatus",
    query: {enabled: false,},
  });

  if (
    ctxAddress &&
    responseDataFlareStatus.data !== null &&
    responseDataFlareStatus.data !== undefined &&
    ctxIsEnabled == null
  ) {
    ctxSetIsEnabled(responseDataFlareStatus.data as boolean);
  }

  const responseDataThreshholdStatus = useReadContract({
    ...flareWhitelistContract,
    functionName: "getTokenThreshHold",
    query: {},
  });

  if (
    ctxAddress &&
    responseDataThreshholdStatus.data !== null &&
    responseDataThreshholdStatus.data !== undefined &&
    threshholdValue == null
  ) {
    setThreshholdValue(responseDataThreshholdStatus.data as bigint);
  }


  const {data: mkfCoinOwnerOfData, refetch: mkfCoinOwnerOfRefetch} =
    useReadContract({
      ...mkfCoinContract,
      functionName: "balanceOf",
      args: [
        ctxAddress || "0x0000000000000000000000000000000000000000"
      ],
      query: {},
    });
  if (
    ctxAddress &&
    ctxToken &&
    mkfCoinOwnerOfData !== null &&
    mkfCoinOwnerOfData !== undefined &&
    mkfCoinOwnerOfData !== balanceOf
  ) {
    setBalanceOf(mkfCoinOwnerOfData as bigint);
  }

  if (
    ctxAddress !== walletClient?.account.address.toLowerCase()
  ) {
    if (walletClient?.account.address) {
      ctxSetAddress(walletClient.account.address.toLowerCase() as Address);
    }else {
      ctxSetAddress(null);
    }
  }
  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    if (
      walletClient?.account.address &&
      ctxAddress !== walletClient?.account.address.toLowerCase()
    ) {
      ctxSetAddress(walletClient.account.address.toLowerCase() as Address);
    }
    if(show) {
      show()
    }

  };
  const submit = () => {
    if (ctxIsWhitelisted || ctxIsRemoved){
      setLastCall('exitWhitelist');
      writeContract({
        ...flareWhitelistContract,
        functionName: "exitWhitelist",
        args: [
        ],
      });
      setShowError(false);

    } else if (ctxIsEnabled && !ctxIsWhitelisted && !ctxIsRemoved) {
      if((Number(stakeValue?.replace(",", ".")?? "0") < 600)) {
        ctxSetError("Minimum value is $600.00");
      } else if((mkfAllowance as bigint) >= tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18)){
        setLastCall('enterWhitelist');
        writeContract({
          ...flareWhitelistContract,
          functionName: "enterWhitelist",
          args: [
            BigInt(JSBI.divide(JSBI.multiply(JSBI.BigInt(tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18).toString()), JSBI.BigInt(10e17)), JSBI.BigInt(valueInUsd?.toString() ?? "1")).toString())
          ],
        });
      } else {
        setLastCall('Approve');
        writeContract({
          ...mkfCoinContract,
          functionName: "approve",
          args: [flareWhitelistContract.address, uint256MaxValue],
        });
      }
    }
    else {
      ctxSetError("Whitelist is currently closed. ");
    }
  };
  useEffect(()=>{
    setIsFirstRender(false);
    if(ctxAddress != null){
      responseDataWhitelistStatus.refetch();
      mkfCoinOwnerOfRefetch();
      responseDataFlareStatus.refetch();
      if (ctxIsLoading){
        (() => {const timer = setTimeout(() => {
          ctxSetIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);})()
      }
    }
  }, [ctxAddress])
  useEffect(()=>{
    setIsFirstRender(false);
    if(ctxAddress != null){
      responseDataWhitelistStatus.refetch();
      mkfCoinOwnerOfRefetch();
      responseDataFlareStatus.refetch();
      if (ctxIsLoading){
        (() => {const timer = setTimeout(() => {
          ctxSetIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);})()
      }
    }
  }, [ctxIsWhitelisted])

  if (isFirstRender) {
    setIsFirstRender(false);
    if(ctxAddress != null){
      responseDataWhitelistStatus.refetch();
      mkfCoinOwnerOfRefetch();
      responseDataFlareStatus.refetch();
      if (ctxIsLoading){
        (() => {const timer = setTimeout(() => {
          ctxSetIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);})()
      }
    }
  }
  return (
    <>
      <div className='app-body'>
        <div className="effect">
          <img src="/assets/effect.png" alt="vector" />
        </div>

        {isLoading?
        <>
        <div className="logoIntro">
          <img src="/assets/logoDentroNew.png" alt="logo" />
          <img src="/assets/logoForaNew.png" alt="logo" />
        </div>
          {isConfirming?
          ( <>
          <h2 className='loading-title'>Confirming Transaction...<br></br>This may take few minutes</h2></>)
          : <></>}
          </>
          :<></>}
        {!isLoading?
        <main>
          <div className="logo">
            <img src="/assets/LogoFlare.png" alt="megaflare logo" />
          </div>
            {!ctxIsWhitelisted && !ctxIsRemoved?
              (
                ctxIsEnabled == null || ctxIsEnabled == true?
                <>
                  <div className="nameContainer">
                    <h2>Hello {ctxRegistrationName}.<br/>{ctxRegistrationEmail}<br/><br/></h2>
                    <div className="leftContainer">
                      <div>
                        <label className="walletBalance">Wallet Balance:</label>
                        <label className="input-right-low flipContainer">
                          <img src="/assets/flip.png" alt="mkf logo" className="mLogo" style={{height: '25px', width: '25px'}}/>
                          {twoDecimals((Number(balanceOf)/10e17).toString())}MKF</label>
                        </div>
                    </div>

                  </div>
                </>
                :
                <>
                  <div className="nameContainer">
                    <h2>First batch closed<br/>More spots coming soon<br/><br/></h2>
                  </div>
                </>
              ):
              (ctxIsWhitelisted?
                (
                <>
                  <div className="nameContainer">
                    <h2>Hello {ctxRegistrationName}.<br/>{ctxRegistrationEmail}</h2>
                    <label className="walletBalance">Balance:</label>
                    <label className="input-right-low flipContainer">
                      <img src="/assets/flip.png" alt="mkf logo" className="mLogo" style={{height: '25px', width: '25px'}}/>
                      {twoDecimals((Number(stakedValue)/10e17).toFixed(2))}MKF</label>
                  </div>
                  <div>
                    <div className="yellow-bar">
                        Congratulations, you are on the Whitelist.
                    </div>
                  </div>
                </>):

                <h2>Permanent Exit Successful!</h2>)
            }
          {ctxAddress != null ?
            (
              ctxIsWhitelisted == false && ctxIsRemoved == false?
              (
                ctxIsEnabled?
                <>
                <form className="inputContainer">
                <label className="input-right-low">Minimum Required: $600.00 \(MKF\)</label>

                        <input
                          type="text"
                          inputMode="decimal"
                          className="textInputsStaked"
                          minLength={1}
                          maxLength={18}
                          autoComplete="off"
                          autoCorrect="off"
                          pattern="^\$\s[0-9]*[.,]?[0-9]*$"
                          value={ "$ " + (stakeValue ?? BigInt(0).toString())}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            var hasComma = e?.target?.value.match(',') ?? false;
                            let value = e?.target?.value
                            value = value.replace("$ ", "");
                            if (hasComma){
                              value = value.replace(',','.');
                            }
                            if (/^\d*[\.]?\d*$/.test(value)) {
                              if (value.length >= 50)
                                return;
                              if (value.split(".")[1] && value.split(".")[1].length > 2)
                                return;
                              if (hasComma){
                                value = e?.target?.value.replace('.',',');
                              }
                              setStakeValue(value);
                            }
                          }}
                          autoFocus={true}
                          placeholder="0.00"
                          style={{
                            border: `${
                              (tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18)) > BigInt(balanceOf ?? "0")
                              || (JSBI.LT(JSBI.BigInt(tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18).toString()), JSBI.divide(JSBI.multiply(JSBI.BigInt(600), JSBI.BigInt(10e18)),JSBI.BigInt(valueInUsd?.toString() ?? "1" ))))
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                        />
                        <label className="input-right-low"><span className="input-right"style={{
                                  color: `${
                                    (Number(stakeValue?.replace(",", ".")?? "0") < 600)
                                      ? "red"
                                      : "white"
                                  }`,
                                }}>{(JSBI.toNumber(JSBI.divide(JSBI.multiply(JSBI.BigInt(tokenToUint256(stakeValue?.replace(",", ".")?? "0", 18).toString()), JSBI.BigInt(10e17)), JSBI.BigInt(valueInUsd?.toString() ?? "1")))/10e17).toFixed(2)} MKF</span></label>

                  {Number(stakeValue?.replace(",", ".")?? "0")/(Number(valueInUsd)/10e17) > (Number(balanceOf)/10e17)?<label className="input-right-low-red">
                    Not enough balance for {stakeValue} USD</label>: <></>}
                </form>
                  <ConnectKitButton.Custom>
                    {({ show, isConnected, address }) => (
                      <button className="btnConnect" id="show-toast-success" onClick={submit}
                      disabled={
                        (Number(stakeValue?.replace(",", ".")?? "0")/(Number(valueInUsd)/10e17))> (Number(balanceOf)/10e17)
                      }  >Join the Whitelist
                    </button>)}
                  </ConnectKitButton.Custom>
                </>
                :
                <>
                </>
              )
              :
              (ctxIsWhitelisted?
                <>
                <form className="inputContainer">
                  <label className="input-right-low">
                  Click the button below to withdraw your tokens and permanently exit the whitelist.<br/><br/>
                  Haga clic en el botón de abajo para retirar sus tokens y salir de la lista blanca de forma permanente. </label>
                </form>
                  <ConnectKitButton.Custom>
                    {({ show, isConnected, address }) => (
                      <button className="btnConnect" id="show-toast-success" onClick={() => { setShowError(true)}}  >Exit the Whitelist.
                    </button>)}
                  </ConnectKitButton.Custom>
                </>
                :
                <>
                <form className="inputContainer">
                  <label className="input-right-low">Value: <span className="input-right">
                      ${twoDecimals(((Number(stakedValue)/10e17) * Number(valueInUsd)/10e17).toString())}</span></label>
                  <label className="input-right-low">
                  Click the button below to withdraw your tokens and permanently exit the whitelist.<br/><br/>
                  Haga clic en el botón de abajo para retirar sus tokens y salir de la lista blanca de forma permanente. </label>
                </form>
                  <ConnectKitButton.Custom>
                    {({ show, isConnected, address }) => (
                      <button className="btnConnect" id="show-toast-success" onClick={submit} >Exit the Whitelist.
                    </button>)}
                  </ConnectKitButton.Custom>
                </>)
            )
          :
            <>
              <ConnectKitButton.Custom>
                {({ show, isConnected, address }) => (
                  <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >Connect
                </button>)}
              </ConnectKitButton.Custom>
            </>
          }
          <footer className="footer">
            <img className='footerImg' src="/assets/logoMega.png" alt="MEGA MAKER"/>
          </footer>
        </main>:<></>}
        {showError?<ErrorModalM closeFunction={() => {
          setShowError(false)}} confirmFunction={() => {
            submit()}}/>:<></>}
      </div>
      { ctxError != null && ctxError != "" ? <ErrorModal lastErrorMessage={""} errorMessage={ctxError ?? ""} /> : <></>}

      </>
  );
}

export default App;

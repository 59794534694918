import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment} from 'react';
import  "./Error.css";

function ErrorModalM(prop: {closeFunction: () => void, confirmFunction: () => void}) {
  return (
    <Fragment>
        <div className="danger_modal_bg"></div>
        <div
          className="modal show danger_modal"
          style={{ display: 'block', position: 'initial' }}
        >
          <Modal.Dialog className='fullWidth'>
            <Modal.Header className='danger_modal_header'>
              <Modal.Title className='danger_modal_title'>Attention!!!</Modal.Title>
            </Modal.Header>

            <Modal.Body className='danger_modal_body'>
              <p>Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit.</p><br/>
              <p>Atención, al confirmar esta acción, se dará de baja de la Lista Blanca de Mega Flare y ya no podrá ingresar. Continúe si desea salir.</p><br/>
              <p>Atenção, ao confirmar essa ação, você estará se descadastrando da White List do Mega Flare e não conseguirá mais entrar. Prossiga se deseja sair.</p><br/>
            </Modal.Body>

            <Modal.Footer>
              <Button className="close_button" onClick={prop.closeFunction}> Close </Button>
              <Button className="confirm_button" onClick={prop.confirmFunction}> Confirm </Button>
            </Modal.Footer>

          </Modal.Dialog>
        </div>
    </Fragment>
  );
}

export default ErrorModalM;
import './Auth.css';
import React, {useContext, useEffect, useState} from "react";
import {useReadContract, useWalletClient} from "wagmi";
import {nftContract, mkfCoinContract, balanceOfABI} from "./models/abi";
import {Address} from "./models/types";
import {ToastContainer, toast} from "react-toastify";
import ErrorModal from "./Error";
import useAxios from 'axios-hooks'
import { disconnect } from '@wagmi/core'
import { config } from "./wagmiConfig";

import {ConnectKitButton} from "connectkit";
import {UserContext} from "./UserContext";
import {useSignMessage} from "wagmi";

function App() {
  const [lastToast, setLastToast] = useState(null as string | null);
  const [lastToastString, setLastToastString] = useState(null as string | null);
  const [balanceOf, setBalanceOf] = useState<bigint | undefined>(undefined);
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxSuccess, ctxSetSuccess,
        ctxToken, ctxSetToken, ctxSetIsLoading, ctxError, ctxSetError, 
        ctxLastError, ctxSetLastError, ctxIsParamCorrect, ctxSetAuthenticated,
        ctxRegistrationEmail, ctxRegistrationName, ctxSetRegistrationEmail, ctxSetRegistrationName
      } = useContext(UserContext);
  const [requestSent, setRequestSent] = useState(false);
  const {data: walletClient} = useWalletClient();
  const [noRequiredToken, setNoRequiredToken] = useState(null as boolean | null);

  useEffect(() => {
    if(ctxSuccess && ctxSuccess == "true") {
      ctxSetAuthenticated(true);
    }
  }, [ctxSuccess]);
  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)

  useEffect(()=>{
    if (ctxIsLoading){
      (() => {const timer = setTimeout(() => {
        ctxSetIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);})()
    }
  }, [ctxIsLoading])
  const [{ data:authData, error: authError, loading: authLoading }, refetchAuth] = useAxios({
    url: 'https://cryptoconferenceapi.produzvideo.com/api/flare',
    method: 'post',
    data: {
      address: ctxAddress,
      name: ctxRegistrationName,
      email: ctxRegistrationEmail,
      ownerOf: Number(ctxToken),
      mkfBalance: balanceOf?.toString()
    }
  },
  {manual: true});

  if (!!authData && !authError && ctxSuccess != authData.success && !authLoading && ctxAddress != null && ctxToken != null && balanceOf != null) {
    if( ctxIsLoading){
      ctxSetIsLoading(false);
    }
    if ((authData?.success ?? "") == "false" && noRequiredToken == null  ) {
      if (ctxError != "Invalid name or email"){
        ctxSetSuccess(authData.success.toString());
      }
    } else {
      ctxSetSuccess(authData.success.toString());
    }
  }
  if (!authData && !!authError && !authLoading && ctxError != "Registration Request Failed" && ctxRegistrationName.length > 0 && ctxRegistrationEmail.length > 5 && ctxAddress != null && ctxToken != null && balanceOf != null && ctxLastError != ctxError) {
    ctxSetError("Registration Request Failed");
    if( ctxIsLoading){
      ctxSetIsLoading(false);
    }
  }

  const [{ data:flareData, error: flareError, loading: flareLoading }, refetchFlare] = useAxios({
    url: `https://cryptoconferenceapi.produzvideo.com/flare/${ctxAddress}`,
    method: 'get'
  });

  if (!!flareData && !flareError && !flareLoading && ctxAddress != null && !requestSent) {
    setRequestSent(true);
    if (flareData.success){
      ctxSetSuccess(true.toString());
      ctxSetRegistrationName(flareData?.name);
      ctxSetRegistrationEmail(flareData?.email);
      ctxSetIsLoading(true);
    }
  }
  useEffect(() => {
    if (ctxAddress != null) {
      refetchFlare().catch(e => {}
      );
    }
  }, [ctxAddress, ctxSuccess])

  useEffect(() => {
    if (ctxAddress != null && balanceOf != null && ctxToken != null && !ctxIsLoading && ctxSuccess != "true") {

      if (ctxRegistrationEmail.length > 5 && ctxRegistrationName.length > 0 && ctxRegistrationEmail.includes("@") && ctxRegistrationEmail.includes(".")){
        refetchAuth().catch(e => {}
        );;
        ctxSetIsLoading(true);
        ctxSetError("");
      }
    }
  }, [ctxAddress, balanceOf, ctxToken])
  const responseDataNft = useReadContract({
    ...nftContract,
    functionName: "getNFTInfo",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });

  if (
    ctxAddress &&
    responseDataNft.data !== null &&
    responseDataNft.data !== undefined &&
    ((responseDataNft.data[0] > 0 && ctxToken !== responseDataNft.data[1]) || (responseDataNft.data[1] <= 0 && ctxToken !== BigInt(-1)))
  ) {
    if (responseDataNft.data[1] <= 0) {
      ctxSetToken(BigInt(-1));
    } else {
      ctxSetToken(responseDataNft.data[0]);
    }
  }

  const {data: mkfCoinOwnerOf, refetch: mkfCoinOwnerOfRefetch} =
    useReadContract({
      ...mkfCoinContract,
      functionName: "balanceOf",
      args: [
        ctxAddress || "0x0000000000000000000000000000000000000000"
      ],
      query: {},
    });
  if (
    ctxAddress &&
    ctxToken &&
    mkfCoinOwnerOf !== null &&
    mkfCoinOwnerOf !== undefined &&
    mkfCoinOwnerOf !== balanceOf
  ) {
    setBalanceOf(mkfCoinOwnerOf as bigint );
  }

  if (
    ctxAddress !== walletClient?.account.address.toLowerCase()
  ) {
    if (walletClient?.account.address) {
      ctxSetAddress(walletClient.account.address.toLowerCase() as Address);
    }else {
      ctxSetAddress(null);
    }
  }

  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
    ctxSetError("");
    if(show) {
      show()
    }
  };
  const submit = () => {
    if (ctxRegistrationEmail.length > 5 && ctxRegistrationName.length > 0 && ctxRegistrationEmail.includes("@") && ctxRegistrationEmail.includes(".")){
      refetchAuth().catch(e => {}
      );;
      ctxSetIsLoading(true);
      ctxSetError("");

    } else {
      setLastToastString("");
      ctxSetError("Invalid Email or Name");
    }
  };
  if (ctxError) {
    var id = lastToast;
    if (ctxError == "Invalid Email or Name" && ctxError !=  lastToastString && lastToastString != "Invalid Email or Name"){
      id = (toast.error("Invalid Email or Name", {toastId: "Invalid Email or Name"})).toString();
      setLastToast(id);
      setLastToastString("Invalid Email or Name");
  
    } else if (ctxError == "Registration Request Failed" && ctxError !=  lastToastString && lastToastString != "Registration Request Failed"){
      id = (toast.error("Registration Request Failed", {toastId: "Registration Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Registration Request Failed");
  
    } else if (ctxError == "Api Error" && lastToastString != "Unknown Server Error"){
      id = (toast.error("Unknown Server Error", {toastId: "Unknown Server Error"})).toString();
      setLastToast(id);
      setLastToastString("Unknown Server Error");
    }
  }
  return (
    <>
      <div className='app-body'>
        <div className="effect">
          <img src="/assets/effect.png" alt="vector" />
        </div>

        {ctxIsLoading?<div className="logoIntro">
          <img src="/assets/logoDentroNew.png" alt="logo" />
          <img src="/assets/logoForaNew.png" alt="logo" />
        </div>:<></>}
        {!ctxIsLoading && !ctxSuccess && !noRequiredToken?
        <main>
          <div className="logo">
            <img src="/assets/LogoFlare.png" alt="megaflare logo" />
          </div>
          <div className="textMain">
            <h1>Join the whitelist now.</h1>
          </div>
          {ctxAddress != null?
            <>
              <form className="inputContainer">
                <label className="inputLabel">Name</label>
                <input id="name-input" type="text" className="textInputs" value={ctxRegistrationName} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationName(value);
                }}/>
                <label className="inputLabel">Email</label>
                <input id="email-input" type="email" className="textInputs" value={ctxRegistrationEmail} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationEmail(value);
                }} />
              </form>
              <ConnectKitButton.Custom>
                {({ show, isConnected, address }) => (
                  <button className="btnConnect" id="show-toast-success" onClick={submit}  >Submit
                </button>)}
              </ConnectKitButton.Custom>
          </>
          :
            <>
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >Connect
                  </button>)}
                </ConnectKitButton.Custom>
              </>
          }
          <footer className="footer">
            <img className='footerImg' src="/assets/logoMega.png" alt="MEGA MAKER"/>
          </footer>
        </main>:
        ( !ctxIsLoading && ctxSuccess=='false'?
        <main>
          <div className="logo">
            <img src="/assets/LogoFlare.png" alt="megaflare logo" />
          </div>
          <div className="textMain">
            <h2>Invalid Email</h2>
          </div>
          <footer className="footer">
            <img className='footerImg' src="/assets/logoMega.png" alt="MEGA MAKER"/>
          </footer>
        </main>            :<></>
        )}
      </div>
        { ctxError != null && ctxError != "" ? <ErrorModal lastErrorMessage={lastToastString ?? ""} errorMessage={ctxError ?? ""} /> : <></>}
      </>
  );
}

export default App;

import { createConfig, http, CreateConfigParameters, Config } from "wagmi";
import { getDefaultConfig } from "connectkit";
import { polygon } from "wagmi/chains"
import { injected, walletConnect  } from 'wagmi/connectors'
import { type Chain } from 'viem'

const configExecutor: (parameters: CreateConfigParameters) => Config  = createConfig;

export const testNet = {
  id: 1,
  name: 'Anvil Testnet',
  nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['http://127.0.0.1:8546/'] },
    // public: { http: ['http://127.0.0.1:8546/'] },
    // default: { http: ['https://rpc-amoy.polygon.technology'] },
    // public: { http: ['https://polygon-amoy-bor-rpc.publicnode.com'] },
  },
  // blockExplorers: {
  //   default: { name: 'oklink', url: 'https://www.oklink.com/amoy' },
  // },
  testnet: true
} as const satisfies Chain;

const parameters = {
  chains: [testNet],
  connectors: undefined,
  transports: {
    [testNet.id]: http()
  },
} as CreateConfigParameters;

export const config = configExecutor(
  getDefaultConfig({
    // chains: [testNet],
    // transports: {
    //   [testNet.id]: http()
    // },
    chains: [polygon],
    transports: {
      [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/QETWM1FfuLaQ2yEVKhSKAB9AkfsBq4-4`),
    },
    connectors: [injected(),walletConnect({projectId: "f1011529a650403b9293876045da1a41"}) ],

    walletConnectProjectId: "",

    appName: "FTKXMarket",

    appDescription: "",
    appUrl: "https://teste.megaplace.org/", // your app's url
    appIcon: "https://family.co/logo.png",
  }),
);
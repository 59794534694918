import React, {createContext} from 'react'
import {UserCoin, UserToken, Address, Offer, OfferTx} from './models/types'

export const UserContext = createContext({
  ctxToken: null as UserToken,
  ctxAddress: null as Address,
  ctxError: null as string | null,
  ctxLastError: null as string | null,
  ctxSuccess: null as string | null,
  ctxMessage: null as string | null,
  ctxSignedMessage: null as string | null,
  ctxIsParamCorrect: null as boolean | null,
  ctxIsLoading: true as boolean,
  ctxIsEnabled: null as boolean | null,
  ctxIsWhitelisted: null as boolean | null,
  ctxIsRemoved: null as boolean | null,
  ctxFirstRequestSent: null as boolean | null,
  ctxRegistrationName: "" as string,
  ctxRegistrationEmail: "" as string,
  ctxSignSuccess: null as boolean | null,
  ctxAuthenticated: null as boolean | null,
  ctxSetToken: ()=>null,
  ctxSetAddress: ()=>null,
  ctxSetError: () => null,
  ctxSetLastError: () => null,
  ctxSetMessage: () => null,
  ctxSetSignedMessage: () => null,
  ctxSetSuccess:() => null,
  ctxSetIsParamCorrect:() => null,
  ctxSetIsLoading:() => null,
  ctxSetIsEnabled:() => null,
  ctxSetIsWhitelisted:() => null,
  ctxSetIsRemoved:() => null,
  ctxSetFirstRequestSent: () => null,
  ctxSetRegistrationName: () => null,
  ctxSetRegistrationEmail: () => null,
  ctxSetSignSuccess: () => null,
  ctxSetAuthenticated: () => null,
} as ContextData)

export type ContextData = {
  ctxToken: UserToken,
  ctxAddress: Address,
  ctxError: null | string,
  ctxLastError: null | string,
  ctxSuccess: null | string,
  ctxMessage: null | string,
  ctxSignedMessage: null | string,
  ctxIsParamCorrect: boolean | null,
  ctxIsLoading: boolean,
  ctxIsEnabled: boolean | null,
  ctxIsWhitelisted: boolean | null,
  ctxIsRemoved: boolean | null,
  ctxFirstRequestSent: boolean | null,
  ctxRegistrationName: string,
  ctxRegistrationEmail: string,
  ctxSignSuccess: boolean | null,
  ctxAuthenticated: boolean | null,
  ctxSetToken: React.Dispatch<React.SetStateAction<bigint | null>>,
  ctxSetAddress: React.Dispatch<React.SetStateAction<`0x${string}` | null>>,
  ctxSetError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetLastError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSuccess: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSignedMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetIsParamCorrect:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ctxSetIsEnabled:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsWhitelisted:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsRemoved:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetFirstRequestSent: React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetRegistrationName: React.Dispatch<React.SetStateAction<string>>,
  ctxSetRegistrationEmail: React.Dispatch<React.SetStateAction<string>>,
  ctxSetSignSuccess: React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>,

}
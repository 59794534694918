export const getNftInfoABI = [
    {
      type: 'function',
      name: 'getNFTInfo',
      stateMutability: 'view',
      inputs: [{ name: 'account', type: 'address' }],
      outputs: [{ type: 'uint256' }, {type: 'uint256'}],
    }
  ] as const
  
  export const nftContract = {
    address: '0xcD0F8b7f1004E09A68ac0639A82Ff9423623043A',
    // address: '0x39dD11C243Ac4Ac250980FA3AEa016f73C509f37', //dev
    abi: getNftInfoABI
  } as const

  export const balanceOfABI = [
    {
      type: 'function',
      name: 'approve',
      inputs: [{ name: 'spender', type: 'address' },
               { name: 'value', type: 'uint256' }],
      outputs: [{ type: 'bool' }],
    },
    {
      type: 'function',
      name: 'allowance',
      inputs: [{ name: 'owner', type: 'address' },
               { name: 'spender', type: 'address' }],
      outputs: [{ type: 'uint256' }],
    },
    {
      type: 'function',
      name: 'balanceOf',
      stateMutability: 'view',
      inputs: [{ name: 'account', type: 'address' }],
      outputs: [{ type: 'uint256' }],
    }
  ] as const

  export const mkfCoinContract = {
    address: '0x66f364f908c662772f5b7ecd58488f372c584833',
    // address: '0xef11D1c2aA48826D4c41e54ab82D1Ff5Ad8A64Ca', //dev
    abi: balanceOfABI
  } as const

  export const getTokenPriceUsd = [
    {
      type: 'function',
      name: 'getTokenPriceInUSD',
      stateMutability: 'view',
      inputs: [{ name: 'address', type: 'address' }],
      outputs: [{ type: 'uint256' }],
    }
  ] as const
  
  export const quoterContract = {
    address: '0xeb554873070ce3cc4159424c3e36ee530e53b32a',
    abi: getTokenPriceUsd
  } as const
  
  export const flareWhitelist = [
    {
      type: 'function',
      name: 'enterWhitelist',
      inputs: [{ name: 'stakeAmount', type: 'uint256' }],
      outputs: [{ type: 'bool' }],
    },
    {
      type: 'function',
      name: 'exitWhitelist',
      outputs: [{ type: 'bool' }],
    },
    {
      type: 'function',
      name: 'getFlareStatus',
      outputs: [{ type: 'bool' }],
    },
    {
      type: 'function',
      name: 'getTokenThreshHold',
      outputs: [{ type: 'uint256' }],
    },
    {
      type: 'function',
      name: 'isUserWhitelisted',
      stateMutability: 'view',
      inputs: [{ name: 'targetUser', type: 'address' },],
      outputs: [{ type: 'bool' },{ type: 'uint256' },],
    },
  ] as const
  
  export const flareWhitelistContract = {
    address: '0x67aa6d1a6ffe1e0ee9f103a94325cb2d60f4add7',
    // '0x152cfb82211d8927686e6c41bb680f8f552ea89f' //test
    // address: '0x76ca03a67C049477FfB09694dFeF00416dB69746', //dev
    abi: flareWhitelist
  } as const
  
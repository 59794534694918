import React, {useContext, useEffect, useState} from "react";
import "./Header.css";
import {ConnectKitButton} from "connectkit";

const Header = () => {
  const [btnMobile, setBtnMobile] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 805) {
        setBtnMobile(true);
      } else {
        setBtnMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header
      className="App-header"
      style={{marginBottom: isOpen ? "125px" : ""}}
    >
      <div className="flexRow fullWidth">
        <div></div>

        <div
          className="connect-button"
        >
          <ConnectKitButton theme="midnight" customTheme={"connect-button"} />
        </div>


        {/* {btnMobile && <Hamburger toggled={isOpen} toggle={setOpen} color="#fff"/>} */}
        {/* {isOpen && (
          <div className="connectRepons">
            <div className="connect-button">
              <ConnectKitButton
                theme="midnight"
                customTheme={"connect-button"}
              />
            </div>
            <div className="connect-button">
              <span className="usdtBadge">
                <Wallet />
                <p>$</p>
                {ctxUsdt ? <p> {ctxUsdt} </p> : <Spinner size="sm" />}
              </span>
            </div>
            <div className="connect-button">
              <span className="ftkxBadge">
                <img src={ftkImg} alt="img" />
                {ctxFtkx ? <p> {ctxFtkx} </p> : <Spinner size="sm" />}
              </span>
            </div>
          </div>
        )} */}
      </div>
    </header>
  );
};

export default Header;
